.container {
    position: absolute;
    z-index: 10000;
    bottom: calc(10 / 1280 * 100vw);
    right: calc(20 / 1280 * 100vw);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 100;
    gap: calc(20 / 1280 * 100vw);
    z-index: 2000;
    width: 100%;
    width: fit-content;
}

.icon_container {
    background-color: rgb(2, 157, 4);
    width: calc(70 / 1280 * 100vw);
    height: calc(70 / 1280 * 100vw);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s all;
    cursor: pointer;
    position: relative;
    z-index: 10000;

}

.icon_container:hover {
    opacity: 0.8;
 }

.chat_icon,
.chat_icon_bland {
    color: #fff;
    width: calc(40 / 1280 * 100vw);
    height: calc(40 / 1280 * 100vw);
    opacity: 1;
    position: absolute;
    transition: 0.5s all;
}

.chat_icon_bland {
    opacity: 0;
}

.call_big{
    position: fixed;

}

.chat_arena,
.chat_arena_bland {
    background: #fff;
    width: calc(300 / 1280 * 100vw);
    padding: calc(10 / 1280 * 100vw);
    height: calc(400 / 640 * 100vh);
    border-radius: calc(20 / 1280 * 100vw);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    transition: 0.5s all;
    transform: scale(1);
    position: relative;
    z-index: 1000;
    transform-origin: bottom right;
}

.chat_arena_bland {
    transform: scale(0);
    width: 0;
    padding: 0;
    height: 0;
    box-shadow: none;
    border-radius: 0;
}

.instruction_area {
    background: #4e4f4e;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: calc(10 / 1280 * 100vw);
    border-radius: calc(10 / 1280 * 100vw);
    display: flex;
    flex-direction: column;
    width: 100%;
}

.grouped_online {
    display: flex;
    gap: calc(2 / 1280 * 100vw);
    font-size: calc(14 / 1280 * 100vw);
    align-items: center;
    text-transform: lowercase;
    color: #fff;
}

.admin_icon {
    width: calc(30 / 1280 * 100vw);
    height: calc(30 / 1280 * 100vw);
    margin-left: calc(-15 / 1280 * 100vw);
}

.chat_with_title {
    font-size: calc(14 / 1280 * 100vw);
    font-weight: bold;
    color: #fff;
}

.online_indicator {
    width: calc(10 / 1280 * 100vw);
    height: calc(10 / 1280 * 100vw);
    background: rgb(178, 253, 92);
    border-radius: 100%;
}

.online_indicator_grouped {
    display: flex;
    align-items: center;
    gap: calc(5 / 1280 * 100vw);
}

.chat_side {
    width: 100%;
    max-height: calc(300 / 1280 * 100vw);
    margin-top: calc(20 / 1280 * 100vw);
    gap: calc(5 / 1280 * 100vw);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    gap: calc(15 / 1280 * 100vw);

}

.main_chat {
    display: flex;
    flex-direction: column;
    gap: calc(3 / 1280 * 100vw);
}

.input_container {
    background: #fff;
    border-top: 1px solid rgb(235, 234, 234);
    margin-top: auto;
    padding: calc(10 / 1280 * 100vw) 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: calc(5 / 1280 * 100vw);
}

.input {
    border: none;
    outline: none;
    font-size: calc(12 / 1280 * 100vw);
    width: 100%;
}

.input::placeholder {
    color: rgb(193, 190, 190);
    font-size: calc(12 / 1280 * 100vw);
}

.send_icon_bg_opa,
.send_icon_bg {
    width: calc(40 / 1280 * 100vw);
    height: calc(30 / 1280 * 100vw);
    opacity: 1;
    transition: 0.5s all;
    background-color: #4e4f4e;
    border-radius: calc(10 / 1280 * 100vw);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
}

.send_icon_bg_opa {
    opacity: 0.2;
    cursor: not-allowed;
}

.send_icon_bg:hover {
    opacity: 0.9;
}

.send_icon {
    width: calc(10 / 1280 * 100vw);
    height: calc(10 / 1280 * 100vw);
}

.image_upload_icon {
    width: calc(20 / 1280 * 100vw);
    height: calc(20 / 1280 * 100vw);
}

.admin_side_clickable_chat_container {
    display: flex;
    background: red;
}


.client_side_chat_container,
.admin_side_chat_container {
    display: flex;
    margin-left: auto;
    overflow-x: wrap;
    width: fit-content;
    max-width: calc(230 / 1280 * 100vw);
    align-items: flex-end;
    justify-content: flex-end;
    background: #FFEAEA;
    padding: calc(5 / 1280 * 100vw) calc(10 / 1280 * 100vw);
    border-radius: calc(10 / 1280 * 100vw);
    color: #F97066;
    font-size: calc(14 / 1280 * 100vw);
    gap: calc(0 / 1280 * 100vw);
    font-weight: bold;
    flex-direction: column;
}

.admin_side_chat_container {
    margin-left: 0;
    background: rgb(78, 79, 78);
    color: #fff;
    align-items: flex-start;
}

.unread_count {
    position: absolute;
    top: 0;
    right: 0;
    background: red;
    color: white;
    border-radius: 100%;
    width: calc(30 / 1280 * 100vw);
    height: calc(30 / 1280 * 100vw);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(14 / 1280 * 100vw);
    font-weight: bold;
}

.time_stamp {
    font-size: calc(10 / 1280 * 100vw);
    color: rgb(163, 163, 163);
}

.date_header {
    font-size: calc(12 / 1280 * 100vw);
    text-align: center;
    font-family: "Inter";
    font-style: normal;
}



.call_container {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    background: #4e4f4ea9;
    color: #fff;
    padding: calc(20 / 1280 * 100vw);
    display: flex;
    align-items: center;
    z-index: 1000;
    justify-content: center;

}


.call_sub_container {
    background: #000;
    padding: calc(40 / 1280 * 100vw) calc(20 / 1280 * 100vw);
    gap: calc(20 / 1280 * 100vw);
    border-radius: calc(20 / 1280 * 100vw);
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.who {
    font-weight: normal;
    margin-left: auto;
    font-size: calc(12 / 1280 * 100vw);
}

.tech_support_title {
    font-size: calc(20 / 1280 * 100vw);
    font-weight: bold;
    text-align: center;
}

.tech_support_sub {
    font-size: calc(16 / 1280 * 100vw);
    color: #bfbfbf;
    text-align: center;
}

.admin_image {
    width: calc(100 / 1280 * 100vw);
    height: calc(100 / 1280 * 100vw);
    border-radius: 100%;
}

.call_btn_container {
    gap: calc(20 / 1280 * 100vw);
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.icon_call {
    width: calc(16 / 1280 * 100vw);
    height: calc(16 / 1280 * 100vw);
}

.call_btn {
    gap: calc(5 / 1280 * 100vw);
    padding: calc(15 / 1280 * 100vw) calc(30 / 1280 * 100vw);
    border-radius: calc(5 / 1280 * 100vw);
    display: flex;
    align-items: center;
    justify-content: center;
    background: green;
    cursor: pointer;
    transition: 0.5s;
}

.mini {
    background: #000;
    padding: calc(10 / 1280 * 100vw) calc(10 / 1280 * 100vw);
    gap: calc(20 / 1280 * 100vw);
    border-radius: calc(20 / 1280 * 100vw);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    width: calc(100 / 1280 * 100vw);
    font-size: calc(12 / 1280 * 100vw);
    text-align: center;
    justify-content: center;
    /* height: calc(100 / 1280 * 100vw); */
}

.end_call_button {
    width: calc(50 / 1280 * 100vw);
    height: calc(50 / 1280 * 100vw);
    background: red;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.call_btn:hover {
    opacity: 0.8;
}

.image_previewer_container,
.image_previewer_container_inactive {
    background: #0000008d;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    flex-direction: column;
    gap: calc(5 / 1280 * 100vw);
    padding: calc(20 / 1280 * 100vw);
    border-radius: calc(20 / 1280 * 100vw);
    transition: 0.5s all;
    transform-origin: bottom right;
    z-index: 1000;
}


.progress_bar_container {
    width: calc(50 / 1280 * 100vw);
    background: #00000056;
    height: calc(50 / 1280 * 100vw);
    padding: calc(10 / 1280 * 100vw);
    border-radius: calc(10 / 1280 * 100vw);
}

.image_previewer_container_inactive {
    transform: scale(0);
    overflow: hidden;
    border: 0;
}


.image_container {
    width: 100%;
    height: 50%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload_btn_grouped {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(10 / 1280 * 100vw);
}

.send {
    padding: calc(5 / 1280 * 100vw) calc(10 / 1280 * 100vw);
    border-radius: calc(10 / 1280 * 100vw);
    font-size: calc(12 / 1280 * 100vw);
    background: #F97066;
    cursor: pointer;
    font-weight: bold;
}

.send:hover {
    opacity: 0.9;
}


.virtualAssistantContainer{
    width: 90%;
    background-color: #4e4f4e;
    margin: calc(10 / 1512 * 100vw) 0;
    border-radius: calc(10 / 1512 * 100vw);
    padding: calc(10 / 1512 * 100vw);
    color: #fff;
    font-size: calc(14 / 1512 * 100vw) ;
}

.subjectTitle{
    font-size: calc(14 / 1512 * 100vw);
    margin-top: calc(20 / 1512 * 100vw);
    font-weight: bold;
}

.groupedSubjects{
    display: flex;
    flex-direction: column;
    gap: calc(10 / 1512 * 100vw);
    margin-top: calc(10 / 1512 * 100vw);
}

.subjectsContainer{
    background: #0000004f;
    padding: calc(10 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    cursor: pointer;
    transition: 0.5s all;
    cursor: pointer;
}

.subjectsContainer:hover{
    opacity: 0.5;
}


.humanText{
    font-size: calc(12 / 1512 * 100vw);
    color: #F97066;
}

@media screen and (max-width: 999px) {
    .container {
        position: absolute;
        bottom: 5px;
        right: 20px;
        padding-left: 40px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        z-index: 100;
        gap: 20px;
    }

    .icon_container {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.5s all;
        cursor: pointer;
    }

   

    .chat_icon,
    .chat_icon_bland {
        color: #fff;
        width: 30px;
        height: 30px;
        opacity: 1;
        position: absolute;
        transition: 0.5s all;
    }

    .chat_icon_bland {
        opacity: 0;
    }

    .chat_arena,
    .chat_arena_bland {
        background: #fff;
        width: 100%;
        padding: 10px;
        height: 670px;
        border-radius: 20px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        transition: 0.5s all;
        transform: scale(1);
        transform-origin: bottom right;
    }

    .chat_arena_bland {
        transform: scale(0);
        width: 0;
        padding: 0;
        height: 0;
        box-shadow: none;
        border-radius: 0;
    }

    .instruction_area {
        background: #4e4f4e;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        padding: 10px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .grouped_online {
        display: flex;
        gap: 2px;
        font-size: 14px;
        align-items: center;
        text-transform: lowercase;
        color: #fff;
    }

    .admin_icon {
        width: 30px;
        height: 30px;
        margin-left: -15px;
    }

    .chat_with_title {
        font-size: 14px;
        font-weight: bold;
        color: #fff;
    }

    .online_indicator {
        width: 10px;
        height: 10px;
        background: rgb(178, 253, 92);
        border-radius: 100%;
    }

    .online_indicator_grouped {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .chat_side {
        width: 100%;
        max-height: initial;
        height: 100%;
        margin-top: 20px;
        gap: 5px;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        gap: 15px;
    }

    .main_chat {
        display: flex;
        flex-direction: column;
        gap: 3px;
    }

    .input_container {
        background: #fff;
        border-top: 1px solid rgb(235, 234, 234);
        margin-top: auto;
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 5px;
    }

    .input {
        border: none;
        outline: none;
        font-size: 12px;
        width: 100%;
    }

    .input::placeholder {
        color: rgb(193, 190, 190);
        font-size: 12px;
    }

    .send_icon_bg_opa,
    .send_icon_bg {
        width: 30px;
        height: 30px;
        opacity: 1;
        transition: 0.5s all;
        background-color: #4e4f4e;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .send_icon_bg_opa {
        opacity: 0.2;
        cursor: not-allowed;
    }

    .send_icon_bg:hover {
        background-color: #FFEAEA;
    }

    .send_icon {
        width: 10px;
        height: 10px;
        color: #fff;
    }

    .admin_side_clickable_chat_container {
        display: flex;
        background: red;
    }

    .client_side_chat_container,
    .admin_side_chat_container {
        display: flex;
        margin-left: auto;
        overflow-x: wrap;
        width: fit-content;
        max-width: 230px;
        align-items: flex-end;
        justify-content: flex-end;
        background: #FFEAEA;
        padding: 5px 10px;
        border-radius: 10px;
        color: #F97066;
        font-size: 14px;
        gap: 5px;
        font-weight: bold;
    }

    .admin_side_chat_container {
        margin-left: 0;
        background: rgb(78, 79, 78);
        color: #fff;
    }

    .unread_count {
        position: absolute;
        top: 0;
        right: 0;
        background: red;
        color: white;
        border-radius: 100%;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: bold;
    }

    .time_stamp {
        font-size: 10px;
        color: rgb(163, 163, 163);
    }

    .date_header {
        font-size: 12px;
        text-align: center;
        font-family: "Inter";
        font-style: normal;
    }

    .call_container {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        height: 100%;
        background: #4e4f4ea9;
        color: #fff;
        padding: 20px;
        display: flex;
        align-items: center;
        z-index: 1000;
        justify-content: center;
    }

    .call_sub_container {
        background: #000;
        padding: 40px 20px;
        gap: 20px;
        border-radius: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .who {
        font-weight: normal;
        margin-left: auto;
        font-size: 12px;
    }

    .tech_support_title {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
    }

    .tech_support_sub {
        font-size: 16px;
        color: #bfbfbf;
        text-align: center;
    }

    .admin_image {
        width: 100px;
        height: 100px;
        border-radius: 100%;
    }

    .call_btn_container {
        gap: 20px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
    }

    .icon_call {
        width: 16px;
        height: 16px;
    }

    .call_btn {
        gap: 5px;
        padding: 15px 30px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: green;
    }

    .mini {
        background: #000;
        padding: 10px 10px;
        gap: 20px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        width: 100px;
        font-size: 12px;
        text-align: center;
        justify-content: center;
        /* height: 100px; */
    }

    .end_call_button {
        width: 50px;
        height: 50px;
        background: red;
        border-radius: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .image_previewer_container,
    .image_previewer_container_inactive {
        background: #0000008d;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        flex-direction: column;
        gap: 5px;
        padding: 20px;
        border-radius: 20px;
        transition: 0.5s all;
        transform-origin: bottom right;
        z-index: 1000;
    }

    .progress_bar_container {
        width: 50px;
        background: #00000056;
        height: 50px;
        padding: 10px;
        border-radius: 10px;
    }

    .image_previewer_container_inactive {
        transform: scale(0);
        overflow: hidden;
        border: 0;
    }

    .image_container {
        width: 100%;
        height: 50%;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .image_upload_icon {
        width: 20;
        height: 20;
    }


}

@media screen and (max-width: 540px) {
    .container {
        position: absolute;
        bottom: calc(5 / 414 * 100vw);
        right: calc(20 / 414 * 100vw);
        padding-left: calc(40 / 414 * 100vw);
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        z-index: 100;
        gap: calc(20 / 414 * 100vw);
    }

    .icon_container {
        width: calc(50 / 414 * 100vw);
        height: calc(50 / 414 * 100vw);
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.5s all;
        cursor: pointer;
    }

   

    .chat_icon,
    .chat_icon_bland {
        color: #fff;
        width: calc(30 / 414 * 100vw);
        height: calc(30 / 414 * 100vw);
        opacity: 1;
        position: absolute;
        transition: 0.5s all;
    }

    .chat_icon_bland {
        opacity: 0;
    }

    .chat_arena,
    .chat_arena_bland {
        background: #fff;
        width: 100%;
        padding: calc(10 / 414 * 100vw);
        height: calc(670 / 896 * 100vh);
        border-radius: calc(20 / 414 * 100vw);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        transition: 0.5s all;
        transform: scale(1);
        transform-origin: bottom right;
    }

    .chat_arena_bland {
        transform: scale(0);
        width: 0;
        padding: 0;
        height: 0;
        box-shadow: none;
        border-radius: 0;
    }

    .instruction_area {
        background: #4e4f4e;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        padding: calc(10 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .grouped_online {
        display: flex;
        gap: calc(2 / 414 * 100vw);
        font-size: calc(14 / 414 * 100vw);
        align-items: center;
        text-transform: lowercase;
        color: #fff;
    }

    .admin_icon {
        width: calc(30 / 414 * 100vw);
        height: calc(30 / 414 * 100vw);
        margin-left: calc(-15 / 414 * 100vw);
    }

    .chat_with_title {
        font-size: calc(14 / 414 * 100vw);
        font-weight: bold;
        color: #fff;
    }

    .online_indicator {
        width: calc(10 / 414 * 100vw);
        height: calc(10 / 414 * 100vw);
        background: rgb(178, 253, 92);
        border-radius: 100%;
    }

    .online_indicator_grouped {
        display: flex;
        align-items: center;
        gap: calc(5 / 414 * 100vw);
    }

    .chat_side {
        width: 100%;
        max-height: initial;
        height: 100%;
        margin-top: calc(20 / 414 * 100vw);
        gap: calc(5 / 414 * 100vw);
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        gap: calc(15 / 414 * 100vw);

    }

    .main_chat {
        display: flex;
        flex-direction: column;
        gap: calc(3 / 414 * 100vw);
    }

    .input_container {
        background: #fff;
        border-top: 1px solid rgb(235, 234, 234);
        margin-top: auto;
        padding: calc(10 / 414 * 100vw) 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: calc(5 / 414 * 100vw);
    }

    .input {
        border: none;
        outline: none;
        font-size: calc(12 / 414 * 100vw);
        width: 100%;
    }

    .input::placeholder {
        color: rgb(193, 190, 190);
        font-size: calc(12 / 414 * 100vw);
    }

    .send_icon_bg_opa,
    .send_icon_bg {
        width: calc(40 / 414 * 100vw);
        height: calc(30 / 414 * 100vw);
        opacity: 1;
        transition: 0.5s all;
        background-color: #4e4f4e;
        border-radius: calc(10 / 414 * 100vw);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .send_icon_bg_opa {
        opacity: 0.2;
        cursor: not-allowed;
    }

    .send_icon_bg:hover {
        background-color: #FFEAEA;
    }

    .send_icon {
        width: calc(10 / 414 * 100vw);
        height: calc(10 / 414 * 100vw);
        color: #fff;
    }

    .admin_side_clickable_chat_container {
        display: flex;
        background: red;
    }


    .client_side_chat_container,
    .admin_side_chat_container {
        display: flex;
        margin-left: auto;
        overflow-x: wrap;
        width: fit-content;
        max-width: calc(230 / 414 * 100vw);
        align-items: flex-end;
        justify-content: flex-end;
        background: #FFEAEA;
        padding: calc(5 / 414 * 100vw) calc(10 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        color: #F97066;
        font-size: calc(14 / 414 * 100vw);
        gap: calc(5 / 414 * 100vw);
        font-weight: bold;
    }

    .admin_side_chat_container {
        margin-left: 0;
        background: rgb(78, 79, 78);
        color: #fff
    }

    .unread_count {
        position: absolute;
        top: 0;
        right: 0;
        background: red;
        color: white;
        border-radius: 100%;
        width: calc(30 / 414 * 100vw);
        height: calc(30 / 414 * 100vw);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: calc(14 / 414 * 100vw);
        font-weight: bold;
    }

    .time_stamp {
        font-size: calc(10 / 414 * 100vw);
        color: rgb(163, 163, 163);

    }

    .date_header {
        font-size: calc(12 / 414 * 100vw);
        text-align: center;
        font-family: "Inter";
        ;
        font-style: normal;
    }

    .call_container {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        height: 100%;
        background: #4e4f4ea9;
        color: #fff;
        padding: calc(20 / 414 * 100vw);
        display: flex;
        align-items: center;
        z-index: 1000;
        justify-content: center;

    }

    .call_sub_container {
        background: #000;
        padding: calc(40 / 414 * 100vw) calc(20 / 414 * 100vw);
        gap: calc(20 / 414 * 100vw);
        border-radius: calc(20 / 414 * 100vw);
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .who {
        font-weight: normal;
        margin-left: auto;
        font-size: calc(12 / 414 * 100vw);
    }

    .tech_support_title {
        font-size: calc(20 / 414 * 100vw);
        font-weight: bold;
        text-align: center;
    }

    .tech_support_sub {
        font-size: calc(16 / 414 * 100vw);
        color: #bfbfbf;
        text-align: center;
    }

    .admin_image {
        width: calc(100 / 414 * 100vw);
        height: calc(100 / 414 * 100vw);
        border-radius: 100%;
    }

    .call_btn_container {
        gap: calc(20 / 414 * 100vw);
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
    }

    .icon_call {
        width: calc(16 / 414 * 100vw);
        height: calc(16 / 414 * 100vw);
    }

    .call_btn {
        gap: calc(5 / 414 * 100vw);
        padding: calc(15 / 414 * 100vw) calc(30 / 414 * 100vw);
        border-radius: calc(5 / 414 * 100vw);
        display: flex;
        align-items: center;
        justify-content: center;
        background: green;
    }

    .mini {
        background: #000;
        padding: calc(10 / 414 * 100vw) calc(10 / 414 * 100vw);
        gap: calc(20 / 414 * 100vw);
        border-radius: calc(20 / 414 * 100vw);
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        width: calc(100 / 414 * 100vw);
        font-size: calc(12 / 414 * 100vw);
        text-align: center;
        justify-content: center;
        /* height: calc(100 / 414 * 100vw); */
    }

    .end_call_button {
        width: calc(50 / 414 * 100vw);
        height: calc(50 / 414 * 100vw);
        background: red;
        border-radius: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }



    .image_previewer_container,
    .image_previewer_container_inactive {
        background: #0000008d;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        flex-direction: column;
        gap: calc(5 / 414 * 100vw);
        padding: calc(20 / 414 * 100vw);
        border-radius: calc(20 / 414 * 100vw);
        transition: 0.5s all;
        transform-origin: bottom right;
        z-index: 1000;
    }


    .progress_bar_container {
        width: calc(50 / 414 * 100vw);
        background: #00000056;
        height: calc(50 / 414 * 100vw);
        padding: calc(10 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
    }

    .image_previewer_container_inactive {
        transform: scale(0);
        overflow: hidden;
        border: 0;
    }


    .image_container {
        width: 100%;
        height: 50%;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .upload_btn_grouped {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: calc(10 / 414 * 100vw);
    }

    .send {
        padding: calc(5 / 414 * 100vw) calc(10 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        font-size: calc(12 / 414 * 100vw);
        background: #F97066;
        cursor: pointer;
        font-weight: bold;
    }

    .send:hover {
        opacity: 0.9;
    }

    .image_upload_icon {
        width: calc(20 / 414 * 100vw);
        height: calc(20 / 414 * 100vw);
    }

}