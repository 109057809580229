.bigContainer {
    /* height: 100vh; */
    margin-top: auto;
}


.container {
    width: 100%;
    overflow: hidden;
    height: 100%;
}




.pick_location {
    padding: calc(15 / 1512 * 100vw);
    transition: 0.5s all;
    cursor: pointer;
    transform-origin: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    padding: calc(15 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    border: calc(2 / 1512 * 100vw) solid rgb(169, 169, 169);
}




.submit_response_btn {
    background: #1c2b42;
    color: #fff;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: calc(20 / 1512 * 100vw);
    height: calc(52 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    margin: 0 auto;
    display: flex;
    gap: calc(10 / 1512 * 100vw);
    margin-top: calc(20 / 1512 * 100vw);
    text-transform: capitalize;
    cursor: pointer;
}

.submit_response_btn:hover {
    opacity: 0.8;
}



.loader_con {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.video_container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
}

.video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.camera_icon_container {
    position: absolute;
    bottom: calc(20 / 1512 * 100vw);
    left: 50%;
    transform: translateX(-50%);
    width: calc(100 / 1512 * 100vw);
    height: calc(100 / 1512 * 100vw);
    border-radius: 50%;
    background: rgba(255, 0, 0, 0.606);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    color: #fff;
    font-weight: bold;
}


.taken_picture {
    width: 100%;
    margin-top: calc(20 / 1512 * 100vw);
}


.load_container {
    width: 100%;
    height: calc(50 / 1512 * 100vw);
    background: black;
    overflow: hidden;
    border-radius: calc(10 / 1512 * 100vw);
}

.inputContainerCon {
    width: 100%;
}

.label {
    color: gray;
    font-size: calc(14 / 1512 * 100vw);
    font-weight: bold;
}

.inputContainer {
    width: 100%;
    padding: calc(15 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    border: calc(2 / 1512 * 100vw) solid rgb(169, 169, 169);
}

.select {
    width: 100%;
    border: none;
    outline: none;
    background: #fff;
}

.formContainer {
    display: flex;
    flex-direction: column;
    gap: calc(15 / 1512 * 100vw);
    width: 100%;
}



@media screen and (max-width: 480px) {
    .container {
        background: #fff;
        width: 100%;
        height: 100%;
        border-radius: calc(10 / 414 * 100vw);
        padding: calc(10 / 414 * 100vw);
        display: flex;
        flex-direction: column;
        gap: calc(10 / 414 * 100vw);
    }


    .pick_location {
        background: rgba(193, 213, 241, 0.52);
        padding: calc(10 / 414 * 100vw);
        transition: 0.5s all;
        cursor: pointer;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding: calc(15 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        border: calc(2 / 414 * 100vw) solid rgb(169, 169, 169);
    }

    .submit_response_btn {
        background: #1c2b42;
        color: #fff;
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: calc(10 / 414 * 100vw);
        height: calc(52 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        margin: 0 auto;
        display: flex;
        gap: calc(10 / 414 * 100vw);
        text-transform: capitalize;
        cursor: pointer;
        margin-top: calc(20 / 414 * 100vw);
        width: 100%;
    }

    .submit_response_btn:hover {
        opacity: 0.8;
    }



    .loader_con {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .camera_icon_container {
        position: absolute;
        bottom: calc(20 / 414 * 100vw);
        left: 50%;
        transform: translateX(-50%);
        width: calc(70 / 414 * 100vw);
        height: calc(70 / 414 * 100vw);
        border-radius: 50%;
        z-index: 100;
        color: #fff;
        font-weight: bold;
        font-size: calc(14 / 414 * 100vw);
    }


    .taken_picture {
        width: 100%;
        margin-top: calc(10 / 414 * 100vw);
    }


    .load_container {
        width: 100%;
        height: calc(50 / 414 * 100vw);
        background: black;
        overflow: hidden;
        border-radius: calc(10 / 414 * 100vw);
    }



    .label {
        color: gray;
        font-size: calc(14 / 414 * 100vw);
        font-weight: bold;
    }

    .inputContainer {
        width: 100%;
        padding: calc(15 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        border: calc(2 / 414 * 100vw) solid rgb(169, 169, 169);
    }

    .select {
        width: 100%;
        border: none;
        outline: none;
    }

    .formContainer {
        display: flex;
        flex-direction: column;
        gap: calc(15 / 414 * 100vw);
        width: 100%;
    }



}