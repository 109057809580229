.overflow-hidden {
    display: none;
}

#metered-frame {
    background: #000;
    width: 100vw;
    height: fit-content;
}

@media screen and (max-width: 999px) {
    #metered-frame {
        background: #000;
        height: fit-content;
    }
}