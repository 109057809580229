* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  /* font-family: 'Roboto', sans-serif; */
}

.App {
  overflow-x: hidden;
  overflow-y: scroll;
  max-width: 480px;
  height: 100vh;
  background-color: #FFF;
  margin: 0 auto;
  position: relative;
}

.App::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}